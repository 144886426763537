const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Budget",
    icon: "file-invoice-dollar",
    path: "/user/budgets",
  },
  {
    name: "Expenses",
    icon: "dollar-sign",
    path: "/user/expenses",
  },
];

export default sidebar;
