const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Budget",
    icon: "file-invoice-dollar",
    path: "/user/budgets",
  },
  {
    name: "Expenses",
    icon: "dollar-sign",
    path: "/user/expenses",
  },
  {
    name: "Investment",
    icon: "money-check-alt",
    path: "/user/investments",
  },
  {
    name: "Savings",
    icon: "hand-holding-usd",
    path: "/user/savings",
  },
  {
    name: "Finanacial Goal",
    icon: "money-bill-wave-alt",
    path: "/user/goals",
  },
  {
    name: "Tipid bot",
    icon: "robot",
    path: "/user/bot",
  },
];

export default sidebar;
