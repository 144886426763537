const sidebar = [
  {
    name: "Dashboard",
    icon: "tachometer-alt",
    path: "/dashboard",
  },
  {
    name: "Users",
    icon: "user-alt",
    path: "/admin/users",
  },
  // {
  //   name: "Financials of users",
  //   icon: "credit-card",
  //   children: [
  //     {
  //       name: "Budget",
  //       path: "/budgets",
  //     },
  //     {
  //       name: "Finanacial Goal",
  //       path: "/goals",
  //     },
  //     {
  //       name: "Investment",
  //       path: "/investments",
  //     },
  //     {
  //       name: "Expenses",
  //       path: "/expenses",
  //     },
  //     {
  //       name: "Savings",
  //       path: "/savings",
  //     },
  //   ],
  // },
];

export default sidebar;
